export default [
  {
    group: 'Главное',
    items: [
      { text: 'Таблица возвратов', icon: 'mdi-clipboard-arrow-left', pageName: 'returns' },
      { text: 'Изменения броней', icon: 'mdi-notebook', pageName: 'booking-changes' },
      { text: 'Балансы поставщиков', icon: 'mdi-bank', pageName: 'suppliers-balance' },
      { text: 'Журнал процессов', icon: 'mdi-robot', pageName: 'process-log' },
      // { text: 'Запуск процессов', icon: 'mdi-play', pageName: 'starting-processes' },
      { text: 'Список процессов', icon: 'mdi-list-box', pageName: 'processes' }
    ]
  },
  {
    group: 'Справочники',
    items: [
      { text: 'Поставщики', icon: 'mdi-truck-delivery', pageName: 'suppliers' },
      { text: 'Федеральные округа', icon: 'mdi-earth', pageName: 'districts' },
      { text: 'Точки возвратов', icon: 'mdi-map-marker', pageName: 'return-points' },
      { text: 'Торговые сети', icon: 'mdi-cart-variant', pageName: 'trading-networks' },
      { text: 'Юр. лица', icon: 'mdi-account-tie-woman', pageName: 'legal-entities' },
      { text: 'Склады RCC', icon: 'mdi-warehouse', pageName: 'rcc-stores' },
      { text: 'Склады ПК', icon: 'mdi-warehouse', pageName: 'pc-stores' },
      { text: 'Статусы возвратов', icon: 'mdi-list-status', pageName: 'return-statuses' },
      { text: 'Детализации статусов', icon: 'mdi-file-document', pageName: 'detailing' },
      { text: 'Регионы X5', icon: 'mdi-map', pageName: 'regions' },
      { text: 'Аналитика', icon: 'mdi-finance', pageName: 'analytics' }
    ]
  },
  {
    group: 'Настройки',
    items: [
      { text: 'Пользователи', icon: 'mdi-account', pageName: 'users' },
      { text: 'Логи', icon: 'mdi-bug', pageName: 'logs' }
      // { text: 'Процессы', icon: 'mdi-cog', pageName: '' },
      // { text: 'Оповещения', icon: 'mdi-bell-ring', pageName: 'notifications' }
    ]
  }
]
